function EncryptionTransformation(db, passphrase) {
  const Encryption =
    window && "TextEncoder" in window
      ? require("../Encryption")
      : require("../NodeEncryption");

  const encryption = Encryption.default(passphrase);

  db.encrypt = encryption.encrypt;
  db.decrypt = encryption.decrypt
  console.log("aca!");
  window.enc = Encryption.default;

  db.transform({
    incoming: function(doc) {
      const transformed = {};
      const toEncrypt = {};
      Object.keys(doc).forEach(function(field) {
        if (field.startsWith("_")) {
          transformed[field] = doc[field];
        } else {
          toEncrypt[field] = doc[field];
        }
      });
      return encryption.encrypt(JSON.stringify(toEncrypt)).then(data => {
        transformed.data = data;
        return transformed;
      });
    },
    outgoing: function(doc) {
      if (!("data" in doc)) {
        return doc;
      }
      const transformed = {};
      Object.keys(doc).forEach(function(field) {
        if (field.startsWith("_")) {
          transformed[field] = doc[field];
        }
      });
      return encryption.decrypt(doc.data).then(data => {
        return { ...transformed, ...JSON.parse(data) };
      });
    }
  });
}

export default EncryptionTransformation;
