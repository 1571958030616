import Dinero from "dinero.js";

const formatAmount = (amount, currency) => {
  amount = Math.round(amount);
  try {
    return Dinero({ amount, currency }).toFormat("$0,0.00");
  } catch (e) {
    return `${currency} ${amount}`;
  }
};

export { formatAmount };
