import React from "react";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dinero from "dinero.js";
import { Typography, TextField } from "@material-ui/core";
import { mapAccountReportsToColumnData as helper } from "./lib/ReportsHelpers";

import { formatAmount } from "./lib/Utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const backgroundColors = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600"
];

const ReportsColumn = props => {
  const classes = useStyles();

  const { accountingService } = props;

  const defaults = {
    maxDepth: 2,
    maxSeries: 8,
    exlude: "",
    negate: false,
    others: ""
  };

  const presets = [
    {
      ...defaults,
      _id: "gastos-generales",
      name: "Gastos generales",
      include: "^Expenses($|:)",
      exclude: "^Expenses:(Taxes:(Empleo|Ganancias)|Auto$)"
    },
    {
      ...defaults,
      _id: "comida",
      name: "Comida",
      include: "^Expenses:(Groceries|Eat Out & Take Away)($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "baby",
      name: "Baby",
      include: "^Expenses:Baby($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "transport",
      name: "Transporte",
      include: "^Expenses:(Auto|Public Transport)($|:)",
      exclude: "^Expenses:Auto:Maintenance",
      maxDepth: 3
    },
    {
      ...defaults,
      _id: "ahorro",
      name: "Ahorros",
      include: "^(Expenses|Income)$",
      exclude: "",
      maxSeries: 1,
      othersName: "Ahorros",
      maxDepth: 1,
      negate: true
    },
    {
      ...defaults,
      _id: "casa",
      name: "Casa",
      include: "^Expenses:Home($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "leisure",
      name: "Leisure",
      include: "^Expenses:(Travel|Socials|Entertainment)($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "health",
      name: "Health & Beauty",
      include: "^Expenses:Health & Beauty($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "clothes",
      name: "Clothes",
      include: "^Expenses:Clothes($|:)",
      exclude: "",
      maxDepth: 4
    },
    {
      ...defaults,
      _id: "liabilities",
      name: "Deudas",
      include: "^Liabilities($|:)",
      exclude: "",
      maxDepth: 4,
      negate: true
    }
  ];

  const currencies = accountingService.getCurrencies();
  const [rawData, setRawData] = React.useState({});
  const [data, setData] = React.useState({ labels: [], datasets: [] });
  const [totals, setTotals] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [options, setOptions] = React.useState({
    currency: currencies[0],
    include: presets[0].include,
    exclude: presets[0].exclude,
    maxDepth: presets[0].maxDepth || 2,
    maxSeries: presets[0].maxSeries || 8
  });
  const [from, setFrom] = React.useState("2019-07");
  const [to, setTo] = React.useState(new Date().toISOString().substr(0, 7));
  const [preset, setPreset] = React.useState(presets[0]._id);

  presets.sort((a, b) => a.name.localeCompare(b.name));

  const presetsIdx = {};
  presets.forEach(p => (presetsIdx[p._id] = p));

  function applyPreset(presetId) {
    const newPreset = presetsIdx[presetId];
    setPreset(presetId);
    setOptions({ ...options, ...newPreset });
  }

  React.useEffect(() => {
    let mounted = true;
    const getAccounts = async () => {
      const currentAccounts = await accountingService.getAccountsReport(
        from,
        to,
        undefined,
        false
      );
      console.log(currentAccounts);
      if (mounted) {
        setRawData(currentAccounts);
      }
    };
    getAccounts();
    return () => (mounted = false);
  }, [from, to]);

  React.useEffect(() => {
    console.log("Adapting with options", options);
    const adapted = helper(rawData, options);

    const labels = adapted.periods.map(p => [
      p.period,
      formatAmount(p.total, options.currency)
    ]);
    const newTotals = adapted.periods.map(p => p.total);
    const datasets = adapted.series.map((d, i) => ({
      label: d.label,
      data: d.values,
      backgroundColor: backgroundColors[i]
    }));

    const data = {
      labels: labels,
      datasets: datasets
    };
    setData(data);
    setTotals(newTotals);
    setTotal(newTotals.reduce((prev, cur) => prev + cur, 0));
  }, [options, rawData]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="currency">Currency</InputLabel>
        <Select
          native
          value={options.currency}
          onChange={e => setOptions({ ...options, currency: e.target.value })}
          inputProps={{
            name: "currency",
            id: "currency"
          }}
        >
          {currencies.map(m => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="preset">Preset</InputLabel>
        <Select
          native
          value={preset}
          onChange={e => applyPreset(e.target.value)}
          inputProps={{
            name: "preset",
            id: "preset"
          }}
        >
          {presets.map(p => (
            <option key={p._id} value={p._id}>
              {p.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Typography variant="h4" align="center">
        Total for period {formatAmount(total, options.currency)}
      </Typography>
      <Bar
        data={data}
        height={400}
        options={{
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  callback: function(value, index, values) {
                    if (data.datasets.length > 0) {
                      return formatAmount(value, options.currency);
                    } else {
                      return value;
                    }
                  }
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += ": ";
                }

                var amount =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                label += formatAmount(amount, options.currency);

                label += ` (${(
                  (100 * amount) /
                  totals[tooltipItem.index]
                ).toFixed(0)}%)`;

                //label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
              }
            }
          }
        }}
      />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="maxSlices" shrink={true}>
          Max. Slices
        </InputLabel>
        <TextField
          id="maxSlices"
          value={options.maxSeries}
          onChange={e => setOptions({ ...options, maxSeries: e.target.value })}
          type="number"
          step="1"
          min="1"
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="maxDepth" shrink={true}>
          Acc. Depth
        </InputLabel>
        <TextField
          id="maxDepth"
          value={options.maxDepth}
          onChange={e => setOptions({ ...options, maxDepth: e.target.value })}
          type="number"
          step="1"
          min="2"
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="exclude" shrink={true}>
          Exclude
        </InputLabel>
        <TextField
          id="exclude"
          value={options.exclude}
          onChange={e => setOptions({ ...options, exclude: e.target.value })}
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="include" shrink={true}>
          Include
        </InputLabel>
        <TextField
          id="include"
          value={options.include}
          onChange={e => setOptions({ ...options, include: e.target.value })}
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="from" shrink={true}>
          From
        </InputLabel>
        <TextField
          id="from"
          value={from}
          onChange={e => setFrom(e.target.value)}
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="to" shrink={true}>
          To
        </InputLabel>
        <TextField
          id="to"
          value={to}
          onChange={e => setTo(e.target.value)}
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
    </div>
  );
};

export default ReportsColumn;
