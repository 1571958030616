function getTotalsByCurrency(postings) {
  const totals = {};
  postings.forEach(p => {
    if (p.currency in totals) {
      totals[p.currency] += p.amount;
    } else {
      totals[p.currency] = p.amount;
    }
  });
  return totals;
}

function getExplicitExchangeRates(totalsByCurrency) {
  const nonZeroTotals = Object.entries(totalsByCurrency).filter(
    ([currency, amount]) => amount !== 0
  );

  // we can only guess for two
  if (nonZeroTotals.length !== 2) {
    return null;
  }

  // they have to be opposite signs
  if (nonZeroTotals[0][1] < 0 && nonZeroTotals[1][1] < 0) {
    return null;
  }
  if (nonZeroTotals[0][1] > 0 && nonZeroTotals[1][1] > 0) {
    return null;
  }

  return {
    [nonZeroTotals[0][0]]:
      Math.abs(nonZeroTotals[1][1]) / Math.abs(nonZeroTotals[0][1]),
    [nonZeroTotals[1][0]]:
      Math.abs(nonZeroTotals[0][1]) / Math.abs(nonZeroTotals[1][1])
  };
}

function isBalanced(postings) {
  if (postings.size < 2) {
    return false;
  }

  const totalsByCurrency = getTotalsByCurrency(postings);

  const explicitExchangeRates = getExplicitExchangeRates(totalsByCurrency);

  if (null !== explicitExchangeRates) {
    return true;
  }

  for (let k in totalsByCurrency) {
    if (totalsByCurrency[k] !== 0) {
      return false;
    }
  }
  return true;
}

function getRemainingBalance(postings) {
  const totalsByCurrency = getTotalsByCurrency(postings);
  const allEntries = Object.entries(totalsByCurrency);
  if (allEntries.length !== 1 || allEntries[0][1] === 0) {
    return null;
  }
  
  return {
    currency: allEntries[0][0],
    amount: -1 * allEntries[0][1]
  }
}

export { isBalanced, getRemainingBalance };
