const ExpandAccount = (sep = ":") => {
  return function(account) {
    const parts = account.split(sep);
    if (parts.length === 1) {
      return parts;
    }
    const accounts = new Array(parts.length);
    let prev = parts[0];
    accounts[0] = prev;
    for (let i = 1; i < parts.length; i++) {
      prev += sep + parts[i];
      accounts[i] = prev;
    }
    return accounts;
  };
};

export default ExpandAccount;
