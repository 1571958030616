function mapAccountReportsToColumnData(data, options) {
  const defs = {
    currency: "ARS",
    include: /^Expenses($|:)/,
    exclude: undefined,
    maxDepth: 2,
    maxSeries: 7,
    othersName: "(others)",
    negate: false
  };
  const opts = { ...defs, ...options };

  try {
    "test".match(opts.include);
  } catch (e) {
    opts.include = defs.include;
  }

  if (opts.exclude) {
    try {
      "test".match(opts.exclude);
    } catch (e) {
      opts.exclude = defs.exclude;
    }
  }

  const accountIdx = {};
  const includedIdx = {};

  const out = {
    periods: [],
    series: []
  };

  if (!(opts.currency in data)) {
    return out;
  }

  const entries = Object.entries(data[opts.currency]);
  entries.sort((a, b) => a[0].localeCompare(b[0]));
  const periods = entries.map(e => e[0]);
  const arrayOfDataByAccount = entries.map(e => e[1]);

  arrayOfDataByAccount.forEach(function(rawAccounts, valueIndex) {
    let filtered = [];
    let excluded = [];
    Object.entries(rawAccounts).forEach(([account, summary]) => {
      if (account.match(opts.include) && summary.count > 0) {
        const parts = account.split(":");
        const parents = [];
        // FIX TEST Expenses:Taxes:Ganancias
        if (parts.length > 1) {
          parents.push(parts[0]);
          for (let j = 1; j < parts.length - 1; j++) {
            parents.push(parents[j - 1] + ":" + parts[j]);
          }
        }
        const data = {
          name: account,
          valueIndex: valueIndex,
          sum: summary.sum,
          count: summary.count,
          depth: parts.length,
          parents: parents
        };
        if (opts.exclude && account.match(opts.exclude)) {
          excluded.push(data);
        } else if (data.depth <= opts.maxDepth) {
          filtered.push(data);
        }
      }
    });

    filtered.forEach(a => {
      includedIdx[a.name] = 1;
      if (a.name in accountIdx) {
        accountIdx[a.name].sum += a.sum;
        accountIdx[a.name].count += a.count;
        accountIdx[a.name].values[a.valueIndex] = a.sum;
      } else {
        accountIdx[a.name] = { ...a, values: periods.map(p => 0) };
        accountIdx[a.name].values[a.valueIndex] = a.sum;
      }
    });

    excluded
      .filter(a => a.parents.length > 0)
      .forEach(a => {
        a.parents
          .filter(p => p in accountIdx)
          .forEach(p => {
            accountIdx[p].sum -= a.sum;
            accountIdx[p].count -= a.count;
            accountIdx[p].values[a.valueIndex] -= a.sum;
          });
      });
  });

  const accounts = Object.keys(accountIdx);
  accounts.sort();
  for (let i = 0; i < accounts.length - 1; i++) {
    const account = accounts[i];
    let myChildren = accounts
      .slice(i + 1)
      .filter(a => a.startsWith(`${account}:`));
    if (myChildren.length === 0) {
      continue;
    }
    let toSubstractSum = 0;
    let toSubstractCount = 0;
    Object.entries(accountIdx).forEach(([key, value]) => {
      if (myChildren.indexOf(key) > -1) {
        toSubstractSum += value.sum;
        toSubstractCount += value.count;
        // FIX TEST
        value.values.forEach((val, i) => {
          accountIdx[account].values[i] -= value.values[i];
        });
      }
    });
    accountIdx[account].sum -= toSubstractSum;
    accountIdx[account].count -= toSubstractCount;
  }

  let filtered = [];
  Object.keys(includedIdx).forEach(name => filtered.push(accountIdx[name]));
  filtered = filtered.filter(a => a.count > 0);
  filtered.sort((a, b) => b.sum - a.sum);
  const chosenAccounts = filtered.map(a => a.name).slice(0, opts.maxSeries - 1);
  const otherAccounts = filtered.map(a => a.name).slice(opts.maxSeries - 1);

  chosenAccounts.forEach(acc =>
    out.series.push({
      label: acc,
      values: accountIdx[acc].values
    })
  );

  if (otherAccounts.length > 0) {
    const others = {
      label: opts.othersName,
      values: new Array(entries.length).fill(0)
    };
    otherAccounts.forEach(acc =>
      accountIdx[acc].values.forEach((val, i) => (others.values[i] += val))
    );
    out.series.push(others);
  }

  periods.forEach((period, i) => {
    let total = 0;
    out.series.forEach(s => (total += s.values[i]));
    out.periods[i] = {
      period: period,
      total: total
    };
  });

  if (opts.negate) {
    out.periods.forEach(p => (p.total *= -1));
    out.series.forEach(s => (s.values = s.values.map(v => v * -1)));
  }

  return out;
}

export { mapAccountReportsToColumnData };
