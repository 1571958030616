import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TransactionList from "./TransactionList";
import debounce from "lodash.debounce";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  inline: {
    display: "inline"
  },
  fab: {
    position: "fixed",
    zIndex: 1,
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const Transactions = props => {
  const classes = useStyles();

  const { accountingService, lastTs, editTransaction, accountFilter } = props;

  const currentViewHash = [lastTs, accountFilter].join("|");

  const [transactions, setTransactions] = React.useState([]);
  const [nextLastId, setNextLastId] = React.useState(null);
  const [viewParams, setViewParams] = React.useState(null);

  const PAGE_SIZE = 50;

  React.useEffect(() => {
    let isMounted = true;

    const getTransactions = async () => {
      if (null === viewParams) {
        console.log("Not yet initialized. Bye!");
        return;
      }
      console.log("Fetching from lastId", viewParams);
      const txns = await accountingService.getTransactions(
        viewParams.accountFilter,
        PAGE_SIZE,
        viewParams.lastId
      );

      if (isMounted) {
        if (
          viewParams.prev === null ||
          viewParams.prev === viewParams.current
        ) {
          console.log("Es lo mismo!");
          setTransactions(t => t.concat(txns));
        } else {
          setTransactions(txns);
        }
        const newLastId =
          txns.length > 0 ? txns[txns.length - 1]._id : viewParams.lastId;
        setNextLastId(newLastId);
      }
    };

    getTransactions();

    return () => {
      isMounted = false;
    };
  }, [accountingService, viewParams]);

  React.useEffect(() => {
    console.log("Setting view hash", currentViewHash, accountFilter);
    setViewParams(prev => ({
      prev: null === prev ? null : prev.current,
      current: currentViewHash,
      accountFilter,
      lastId: null
    }));
  }, [currentViewHash, accountFilter]);

  React.useEffect(() => {
    if (null === nextLastId) {
      console.log("Skipping becuase nextLastId is null");
      return;
    }

    const scrollListener = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        console.log("Fetching next page", nextLastId);
        setViewParams(prev => ({
          ...prev,
          prev: prev.current,
          lastId: nextLastId
        }));
      }
    }, 100);

    console.log("Adding listener", nextLastId);
    window.addEventListener("scroll", scrollListener);

    return () => {
      console.log("Removing listener");
      window.removeEventListener("scroll", scrollListener);
    };
  }, [nextLastId]);

  return (
    <React.Fragment>
      <TransactionList
        transactions={transactions}
        selectedAccount={accountFilter}
        onClickTransaction={editTransaction}
      />
      <Fab
        color="primary"
        aria-label="Add"
        className={classes.fab}
        onClick={() => editTransaction(null)}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Transactions;
