const FrequentTxns = () => {
  const counts = {};
  const postings = {};
  let mapped = [];

  return {
    addTxns(transactions) {
      transactions.forEach(txn => {
        if (txn.description in counts) {
          counts[txn.description] += 1;
        } else {
          const myPostings = txn.postings.slice();
          myPostings.sort((a, b) => b.amount - a.amount);
          counts[txn.description] = 1;
          postings[txn.description] = myPostings
            .map(p => p.account)
            .slice(0, 2);
        }
      });
      const entries = Object.entries(counts);
      entries.sort((a, b) => b[1] - a[1]);
      mapped = entries.map(([description]) => {
        return {
          description: description,
          accounts: postings[description]
        };
      });
    },
    getSpecs: () => mapped.slice()
  };
};

export default FrequentTxns;
