import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Formik, Field } from "formik";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const SettingsForm = props => {
  const classes = useStyles();

  const { accountingService, swConfig, onChange } = props;

  const [settings, setSettings] = React.useState({
    sync: {},
    pin: false
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  if (navigator in window) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener("controllerchange", function() {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }

  function handleCloseSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  React.useEffect(() => {
    let isMounted = true;

    const fetchSettings = async () => {
      const currentSettings = await accountingService.getSettings();
      if (isMounted) {
        setSettings(currentSettings);
      }
    };

    fetchSettings();

    return () => (isMounted = false);
  }, []);

  const handleReload = () => {
    const newWorker = swConfig.getWaitingRegistration();
    if (!newWorker) {
      console.log("No waiting registration, bye!");
      return;
    }
    newWorker.postMessage({ action: "skipWaiting" });
  };

  const mapSettings = settings => ({
    syncUrl: settings.sync.url,
    syncUsername: settings.sync.username,
    syncPassword: settings.sync.password,
    syncPassphrase: settings.sync.passphrase,
    autoSync: settings.sync.autoSync,
    pin: "",
    verifyPin: "",
    currentPin: ""
  });

  const mapValues = values => {
    const newSettings = {
      ...settings,
      sync: {
        ...settings.sync,
        url: values.syncUrl,
        username: values.syncUsername,
        password: values.syncPassword,
        passphrase: values.syncPassphrase,
        autoSync: !!values.autoSync
      }
    };
    if (settings.pin.active && values.currentPin) {
      newSettings.pin = {
        pin: values.pin,
        currentPin: values.currentPin
      };
    }
    if (!settings.pin.active && values.pin && values.verifyPin) {
      newSettings.pin = {
        pin: values.pin,
        verifyPin: values.verifyPin
      };
    }
    return newSettings;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" align="center">
        Cloud Storage
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={mapSettings(settings)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          accountingService
            .saveSettings(mapValues(values))
            .then(d => {
              setSettings(d);
              setOpenSnackbar(true);
              setSubmitting(false);
              resetForm();
              if (onChange) onChange();
            })
            .catch(e => {
              window.alert("Error: " + e);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Field
                    id="syncUrl"
                    label="CouchDB Host"
                    component={TextField}
                    value={values.syncUrl}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{
                      inputmode: "url"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                    id="syncUsername"
                    name="syncUsername"
                    label="Username"
                    component={TextField}
                    value={values.syncUsername}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      autocomplete: "username"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                    id="syncPassword"
                    name="syncPassword"
                    label="Password"
                    type="password"
                    component={TextField}
                    value={values.syncPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      autocomplete: "current-password"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    fullWidth
                    id="syncPassphrase"
                    name="syncPassphrase"
                    label="Encryption Passphrase"
                    type="password"
                    component={TextField}
                    value={values.syncPassphrase}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      autocomplete: "current-passphrase"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                    fullWidth
                    id="pin"
                    name="pin"
                    label="New App PIN"
                    type="password"
                    component={TextField}
                    value={values.pin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      autocomplete: "off"
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  {settings.pin.active ? (
                    <Field
                      fullWidth
                      id="currentPin"
                      name="currentPin"
                      label="Current PIN"
                      type="password"
                      component={TextField}
                      value={values.currentPin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        autocomplete: "off"
                      }}
                    />
                  ) : (
                    <Field
                      fullWidth
                      id="verifyPin"
                      name="verifyPin"
                      label="Verify PIN"
                      type="password"
                      component={TextField}
                      value={values.verifyPin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        autocomplete: "off"
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Field
                        fullWidth
                        id="autoSync"
                        name="autoSync"
                        label="Sync automatically"
                        component={Switch}
                        checked={!!values.autoSync}
                        value="true"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Sync on save"
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.button}
                  onClick={() =>
                    window.confirm("Sure??") &&
                    accountingService.deleteLocalDb().then(
                      d => window.alert("ok"),
                      err => window.alert("Error:" + err)
                    )
                  }
                >
                  Delete Local Data
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
                <br />
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.button}
                  onClick={handleReload}
                >
                  Reload
                </Button>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                ContentProps={{
                  "aria-describedby": "message-id"
                }}
                message={<span id="message-id">Saved!</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleCloseSnackbar}
                  >
                    <CloseIcon />
                  </IconButton>
                ]}
              />
              <Typography
                variant="caption"
                component="div"
                style={{ flex: 1 }}
                align="right"
              >
                version 0.0.96
              </Typography>
            </Container>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SettingsForm;
