import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Dinero from "dinero.js";

import { formatAmount } from "./lib/Utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  inline: {
    display: "inline"
  }
}));

const envelopeTypeNames = {
  monthly: "Monthly envelopes",
  yearly: "Yearly/irregular envelopes"
};

const addEnvelopeTypeSubHeaders = envelopes => {
  const byType = {};
  envelopes.forEach(e => {
    if (e.envelopeType in byType) {
      byType[e.envelopeType].envelopes.push(e);
    } else {
      byType[e.envelopeType] = {
        type: e.envelopeType,
        name: envelopeTypeNames[e.envelopeType] || envelopeTypeNames,
        envelopes: [e]
      };
    }
  });
  return Object.values(byType);
};

const isOverBudget = envelope => envelope.balance > envelope.budget;

const calculateProgress = envelope => {
  return (100 * envelope.balance) / envelope.budget;
};

const calculateNegativeProgress = envelope => {
  console.log(envelope.balance / envelope.budget);
  return Math.min(100, 100 * (envelope.balance / envelope.budget - 1));
};

const Envelopes = props => {
  const classes = useStyles();

  const { accountingService } = props;

  const [envelopes, setEnvelopes] = React.useState([]);

  React.useEffect(() => {
    const getEnvelopes = async () => {
      const currentEnvelopes = await accountingService.getCurrentEnvelopes();
      console.log("aca", currentEnvelopes);
      setEnvelopes(addEnvelopeTypeSubHeaders(currentEnvelopes));
    };
    getEnvelopes();
  }, []);

  return (
    <List className={classes.root} subheader={<li />}>
      {envelopes.map(group => (
        <li key={`section-${group.type}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader>{group.name}</ListSubheader>
            {group.envelopes.map(envelope => (
              <ListItem key={envelope._id}>
                <ListItemText
                  primary={envelope.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="div"
                        variant="body2"
                        color="textPrimary"
                        align="right"
                      >
                        {formatAmount(envelope.balance, envelope.currency)}
                      </Typography>
                      {isOverBudget(envelope) ? (
                        <LinearProgress
                          color="secondary"
                          variant="determinate"
                          value={calculateNegativeProgress(envelope)}
                        />
                      ) : (
                        <LinearProgress
                          variant="determinate"
                          value={calculateProgress(envelope)}
                        />
                      )}
                      <Typography
                        component="div"
                        variant="body2"
                        color="textSecondary"
                        align="right"
                      >
                        {formatAmount(envelope.budget, envelope.currency)}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default Envelopes;
