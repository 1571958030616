import uuid from "uuid/v4";
import { today, formatDate } from "./Utils";
import moment from "moment";

const addNegativeChildAccounts = balances => {
  const accounts = Object.keys(balances);
  accounts.sort();
  for (let i = 0; i < accounts.length - 1; i++) {
    const account = accounts[i].replace(/-[A-Z]{3}$/, "");
    let myChildren = accounts
      .slice(i + 1)
      .filter(a => a.startsWith(`${account}:`));
    if (myChildren.length === 0) {
      continue;
    }
    let toSubstract = 0;
    Object.entries(balances).forEach(([key, value]) => {
      if (myChildren.indexOf(key) > -1) {
        toSubstract += value;
      }
    });
    balances[accounts[i]] -= toSubstract;
  }
};

const removeChildData = summary => {
  const accounts = Object.keys(summary);
  accounts.sort();
  for (let i = 0; i < accounts.length - 1; i++) {
    const account = accounts[i];
    let myChildren = accounts
      .slice(i + 1)
      .filter(a => a.startsWith(`${account}:`));
    if (myChildren.length === 0) {
      continue;
    }
    let toSubstractSum = 0;
    let toSubstractCount = 0;
    Object.entries(summary).forEach(([key, value]) => {
      if (myChildren.indexOf(key) > -1) {
        toSubstractSum += value.sum;
        toSubstractCount += value.count;
      }
    });
    summary[accounts[i]].sum -= toSubstractSum;
    summary[accounts[i]].count -= toSubstractCount;
  }
};

const Accounting = async (storePromise, customizations) => {
  const store = await storePromise;
  // TODO remove me
  window.accStore = store;

  const getCurrentEnvelopes = async () => {
    const envelopes = await store.data.getEnvelopes();
    const month = today().substr(0, 7);
    const dataByMonth = await store.data.getMonthlyAccountBalances(
      month,
      month
    );
    const [thisMonth] = dataByMonth.filter(o => o.month === month);
    const accountsMap = {};
    if (thisMonth) {
      thisMonth.accounts.forEach(dataByAccount => {
        const { account } = dataByAccount;
        Object.entries(dataByAccount.currencies).forEach(
          ([currency, value]) => {
            accountsMap[`${account}-${currency}`] = value;
          }
        );
      });
    }
    return envelopes.map(e => {
      e.balance = 0;
      e.budget = e.fillAmount;
      const envelopeBalances = {};
      e.accounts.forEach(a => {
        const key = `${a}-${e.currency}`;
        if (key in accountsMap) {
          envelopeBalances[key] = accountsMap[key].sum;
        } else {
          envelopeBalances[key] = 0;
        }
      });
      addNegativeChildAccounts(envelopeBalances);
      e.balance = Object.values(envelopeBalances).reduce(
        (prev, cur) => (prev += cur),
        0
      );
      return e;
    });
  };

  const getEnvelopeAccounts = () => {
    return store.data.getEnvelopes().then(envelopes => {
      let accounts = [];
      envelopes.forEach(e => (accounts = accounts.concat(e.accounts)));
      return accounts;
    });
  };

  const getSourceAccounts = () => {
    return Promise.resolve(customizations.sourceAccounts);
  };

  const getEmptyTransaction = () => {
    return {
      date: today(),
      description: "",
      notes: "",
      postings: [
        {
          account: customizations.sourceAccounts[0],
          amount: "",
          currency: customizations.currencies[0]
        },
        {
          account: "",
          amount: "",
          currency: customizations.currencies[0]
        }
      ]
    };
  };

  const getCurrencies = () => {
    return customizations.currencies;
  };

  const createDefaultEnvelopes = () => {
    console.log(customizations.defaultEnvelopes);
    const promises = customizations.defaultEnvelopes
      .map((e, i) => ({ ...e, _id: uuid(), type: "envelope", order: i + 1 }))
      .map(e => store.data.saveEnvelope(e));

    return Promise.all(promises);
  };

  async function getAccountsBalance() {
    const rawData = await store.data.getAccountBalances();
    const dataByCurrencyAndAccount = {};
    rawData.forEach(data => {
      const { account } = data;
      Object.entries(data.currencies).forEach(([currency, value]) => {
        if (currency in dataByCurrencyAndAccount) {
          if (account in dataByCurrencyAndAccount[currency]) {
            dataByCurrencyAndAccount[currency][account].sum += value.sum;
            dataByCurrencyAndAccount[currency][account].count += value.count;
          } else {
            dataByCurrencyAndAccount[currency][account] = { ...value };
          }
        } else {
          dataByCurrencyAndAccount[currency] = {
            [account]: { ...value }
          };
        }
      });
    });
    return dataByCurrencyAndAccount;
  }

  async function getAccountsReport(yyyymmFrom, yyyymmTo, skipChildData = true) {
    const rawData = await store.data.getMonthlyAccountBalances(
      yyyymmFrom,
      yyyymmTo
    );
    const dataByDateAndCurrencyAndAccount = {};
    rawData.forEach(dataByMonth => {
      const { month } = dataByMonth;
      dataByMonth.accounts.forEach(data => {
        const { account } = data;
        Object.entries(data.currencies).forEach(([currency, value]) => {
          if (!(currency in dataByDateAndCurrencyAndAccount)) {
            dataByDateAndCurrencyAndAccount[currency] = {};
          }
          if (!(month in dataByDateAndCurrencyAndAccount[currency])) {
            dataByDateAndCurrencyAndAccount[currency][month] = {};
          }
          if (account in dataByDateAndCurrencyAndAccount[currency][month]) {
            dataByDateAndCurrencyAndAccount[currency][month][account].sum +=
              value.sum;
            dataByDateAndCurrencyAndAccount[currency][month][account].count +=
              value.count;
          } else {
            dataByDateAndCurrencyAndAccount[currency][month][account] = {
              ...value
            };
          }
        });
      });
    });
    if (skipChildData) {
      for (let currency in dataByDateAndCurrencyAndAccount) {
        removeChildData(dataByDateAndCurrencyAndAccount[currency]);
      }
    }
    return dataByDateAndCurrencyAndAccount;
  }

  function isSimpleTransaction(txn) {
    if (!txn || !txn.postings) {
      return true;
    }
    if (txn.postings.length !== 2) {
      return false;
    }
    if (txn.postings[0].currency !== txn.postings[1].currency) {
      return false;
    }
    if (!txn.postings[0].date && !txn.postings[1].date) {
      return true;
    }
    return (
      txn.date === txn.postings[0].date && txn.date === txn.postings[1].date
    );
  }

  async function findDuplicates(txns, dateRange = 1, amountLimit = 50) {
    const psort = (a, b) => {
      const currencyCmp = a.currency.localeCompare(b.currency);
      if (currencyCmp === 0) {
        return a - b;
      }
      return currencyCmp;
    };
    const duplicates = [];
    const seenIds = {};
    for (let i = 0; i < txns.length; i++) {
      const txn = txns[i];
      if (txn._id && txn._id in seenIds) {
        duplicates[i] = [];
        continue;
      }
      const dateFrom = formatDate(
        moment(txn.date)
          .subtract("days", dateRange)
          .toDate()
      );
      const dateTo = formatDate(
        moment(txn.date)
          .add("days", 1 + dateRange)
          .toDate()
      );
      const inDateRange = await store.data.getTransactionsByDate(
        dateFrom,
        dateTo
      );
      const possibleMatches = inDateRange.filter(t => {
        if (t._id && t._id in seenIds) {
          return false;
        }
        if (t._id && t._id === txn._id) {
          return false;
        }
        if (t.postings.length !== txn.postings.length) {
          return false;
        }
        const myPostings = txn.postings.slice();
        const hisPostings = t.postings.slice();
        myPostings.sort(psort);
        hisPostings.sort(psort);
        for (let i = 0; i < myPostings.length; i++) {
          if (myPostings[i].currency !== hisPostings[i].currency) {
            return false;
          }
          const matches =
            Math.abs(myPostings[i].amount - hisPostings[i].amount) <=
            amountLimit;
          if (matches) {
            seenIds[t._id] = true;
            seenIds[txn._id] = true;
            return true;
          }
        }
        return false;
      });
      duplicates[i] = possibleMatches;
    }
    return duplicates;
  }

  function getInstallments(date, posting, installments) {
    const amount = Math.abs(posting.amount);
    const installmentAmount = Math.floor(amount / installments);
    const missing = amount - installmentAmount * installments;
    const postings = [];
    const d = moment(date);
    const mult = posting.amount > 0 ? 1 : -1;
    for (let i = 0; i < installments; i++) {
      const p = {
        account: posting.account,
        amount: mult * installmentAmount,
        currency: posting.currency
      };
      if (i > 0) {
        p.date = formatDate(d.add(1, "month").toDate());
      }
      postings.push(p);
    }
    postings[0].amount += mult * missing;
    return postings;
  }

  function cleanWhiteSpace(text, stripQuotes = false) {
    if (typeof text != "string") {
      console.log(text, typeof text);
      return "" + text;
    }
    const clean = text.replace(/\s+/s, " ").replace(/\n+/g, " ");
    if (stripQuotes) {
      return clean.replace(/"/g, "");
    }
    return clean;
  }

  function formatAmount(amount, currency, currencyAfter = false) {
    if (currencyAfter) {
      return (amount / 100).toFixed(2) + " " + currency;
    }
    return currency + " " + (amount / 100).toFixed(2);
  }

  function toHLedger(doc) {
    if (doc.type === "transaction") {
      return toHLedgerTransaction(doc);
    }
    if (doc.type === "price") {
      return toHLedgerPrice(doc);
    }
  }

  function toHLedgerTransaction(txn) {
    let out = `${txn.date} ${cleanWhiteSpace(txn.description)}`;
    if (txn.notes) {
      out += ` ; ${cleanWhiteSpace(txn.notes)}`;
    }

    txn.postings.forEach(p => {
      const currency = p.currency.match(/[0-9]/) ? `"${p.currency}"` : p.currency;
      out += `\n  ${p.account}  ${formatAmount(p.amount, currency)}`;
      const comments = [];

      if (p.description) {
        comments.push(cleanWhiteSpace(p.description));
      }
      if (p.date) {
        comments.push(`date:${p.date}`);
      }
      if (comments.length > 0) {
        out += ` ; ${comments.join(", ")}`;
      }
    });
    out += "\n";

    return out;
  }

  function toHLedgerPrice(doc) {
    return `P ${doc.date} ${doc.commodityA} ${doc.amountInB} ${doc.commodityB}`;
  }

  function cleanAccountName(account) {
    return account.replace(/[\s()&]+/g, "-");
  }

  function toBeancount(txn) {
    let out = `${txn.date} txn "${cleanWhiteSpace(txn.description, true)}"`;
    if (txn.notes) {
      out += ` ; ${cleanWhiteSpace(txn.notes)}`;
    }

    txn.postings.forEach(p => {
      out += `\n  ${cleanAccountName(p.account)}  ${formatAmount(
        p.amount,
        p.currency,
        true
      )}`;
      const comments = [];

      if (p.description) {
        comments.push(cleanWhiteSpace(p.description));
      }
      if (p.date) {
        comments.push(`date:${p.date}`);
      }
      if (comments.length > 0) {
        out += ` ; ${comments.join(", ")}`;
      }
    });
    out += "\n";

    return out;
  }

  async function convertAllTransactionsToHLedger() {
    const txns = await store.data.allTransactions();
    const prices = await store.data.allPrices();
    const currencyFormats = [
      "commodity ARS 1,000.00",
      "commodity EUR 1,000.00",
      "commodity USD 1,000.00",
      "commodity AL30 1,000"
    ];
    return currencyFormats.concat(prices.concat(txns).map(toHLedger));
  }

  function convertAllTransactionsToBeancount() {
    return store.data.allTransactions().then(txns => txns.map(toBeancount));
  }

  function convertAllTransactionsToJSObjects() {
    const keys = ["description", "date", "postings", "notes"];
    return store.data.allTransactions().then(txns =>
      txns.map(t => {
        const pjso = {};
        keys.forEach(k => (pjso[k] = t[k]));
        return pjso;
      })
    );
  }

  return {
    getCurrentEnvelopes,
    getEnvelopeAccounts,
    getSourceAccounts,
    getEmptyTransaction,
    getCurrencies,
    saveTransaction: store.data.saveTransaction,
    remove: store.data.remove,
    getTransactions: store.data.getTransactions,
    getAccountsBalance,
    getAccountsReport,
    getAccountNames: store.data.getAccountNames,
    getFrequentDescriptions: () => store.data.getFrequentSpecs(),
    createDefaultEnvelopes,
    getSettings: store.settings.getSettings,
    saveSettings: store.settings.saveSettings,
    sync: store.sync,
    deleteLocalDb: store.deleteLocalDb,
    isSimpleTransaction,
    findDuplicates,
    getInstallments,
    toHLedger,
    toBeancount,
    convertAllTransactionsToHLedger,
    convertAllTransactionsToBeancount,
    convertAllTransactionsToJSObjects,
    savePrice: store.data.savePrice,
    allTransactions: store.data.allTransactions,
    allPrices: store.data.allPrices,
    validatePin: store.settings.validatePin
  };
};

export default Accounting;
