import PouchDB from "pouchdb";
import Settings from "./Settings";
import DataDatabase from "./DataDatabase";
import FrequentTxns from "./FrequentTxns";
import EncryptionTransformation from "./EncryptionTransformation";
import AccountBalances from "./AccountBalances";

// TODO split this file

const defaults = {
  settings: {
    name: "aoab_settings"
  },
  data: {
    name: "aoab_data"
  },
  customization: {
    accountSeparator: ":"
  }
};

const AccountingApp = async (options = {}) => {
  const opts = { ...defaults };
  if (options.settings) {
    opts.settings = { ...defaults.settings, ...options.settings };
  }
  if (options.data) {
    opts.data = { ...defaults.data, ...options.data };
  }
  if (options.customization) {
    opts.customization = {
      ...defaults.customization,
      ...options.customization
    };
  }

  const balances = AccountBalances(opts.customization.accountSeparator);

  const PDB = options.PouchDB || PouchDB;

  let frequentTxns;

  const settings = Settings({ ...opts.settings, PouchDB: PDB });
  const dataDb = DataDatabase({ ...opts.data, PouchDB: PDB });

  async function init() {
    await settings.init();
    await dataDb.init();
    dataDb.changes().on("change", change => {
      if (frequentTxns && !change.deleted) {
        frequentTxns.addTxns([change.doc]);
      }
    });
  }

  await init();

  const data = {
    ...dataDb,
    getFrequentSpecs() {
      const now = Date.now();
      if (frequentTxns) {
        const mapped = frequentTxns.getSpecs();
        console.log(`Took ${Date.now() - now} ms`);
        return Promise.resolve(mapped);
      }
      frequentTxns = FrequentTxns();
      return dataDb.getTransactions(null, 500).then(transactions => {
        frequentTxns.addTxns(transactions);
        const mapped = frequentTxns.getSpecs();
        console.log(`Took ${Date.now() - now} ms`);
        return mapped;
      });
    },
    getAccountBalances() {
      return dataDb
        .allTransactions()
        .then(docs => balances.calculateAccountBalances(docs));
    },
    getMonthlyAccountBalances(ymFrom = null, ymTo = null) {
      return dataDb.allTransactions().then(res => {
        return balances.calculateMonthlyAccountBalances(res, ymFrom, ymTo);
      });
    }
  };

  return {
    settings,
    data,
    sync() {
      const { sync } = settings.getLastSettings();
      if (!sync.url || !sync.passphrase) {
        return;
      }
      const m = sync.url.match(/^(https?:\/\/)(.*)$/);
      const url = m
        ? `${m[1]}${sync.username}:${sync.password}@${m[2]}`
        : sync.url;
      const targetDb = new PDB(url);

      EncryptionTransformation(targetDb, sync.passphrase);

      return PDB.sync(opts.data.name, targetDb, {
        push: {
          filter: function(doc) {
            return (
              doc.type &&
              ["transaction", "envelope", "price"].indexOf(doc.type) > -1
            );
          }
        }
      });
    },
    async restart(destroy = false) {
      await this.shutdown(destroy);
      await init();
    },
    shutdown: async (destroy = false) => {
      if (destroy) {
        await settings.shutdown(true);
        await dataDb.shutdown(true);
      } else {
        await settings.shutdown();
        await dataDb.shutdown();
      }
    },
    deleteLocalDb: async () => {
      await dataDb.shutdown(true);
      return this.restart();
    }
  };
};

export default AccountingApp;
