import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { getRemainingBalance } from "aoab-core";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const AdvancedEditForm = props => {
  const classes = useStyles();

  const {
    accountingService,
    lastTs,
    onChange,
    transaction,
    setCurrentTransaction,
    onSave
  } = props;

  const emptyTransaction = () => {
    const empty = accountingService.getEmptyTransaction();
    empty.postings = [];
    return empty;
  };

  const emptyPosting = currentPostings => {
    let currency = "";
    let amount = 0;
    if (currentPostings) {
      const remainingBalance = getRemainingBalance(currentPostings);
      if (null !== remainingBalance) {
        ({ currency, amount } = remainingBalance);
      }
    }

    return {
      index: -1,
      account: "",
      amount: amount,
      currency: currency,
      date: "",
      description: ""
    };
  };

  const [txn, setTxn] = React.useState(transaction || emptyTransaction());
  const [posting, setPosting] = React.useState(emptyPosting());
  const [isPostingValid, setIsPostingValid] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const [frequentDescriptions, setFrequentDescriptions] = React.useState([]);
  const [fdIdx, setFdIdx] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const currencies = accountingService.getCurrencies();

  function handleCloseSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  const saveTransaction = () => {
    if (!txn.date) {
      setMessage("Date is required");
      setOpenSnackbar(true);
      return;
    }
    if (!txn.description) {
      setMessage("Description is required");
      setOpenSnackbar(true);
      return;
    }
    if (txn.postings.length === 0) {
      setMessage("Postings are missing");
      setOpenSnackbar(true);
    }

    setSubmitting(true);
    accountingService
      .saveTransaction(txn)
      .then(() => {
        onChange();
        setTxn(emptyTransaction());
        setMessage("Saved!");
        setOpenSnackbar(true);
        if (onSave) onSave();
      })
      .catch(e => {
        console.log(e);
        setMessage(e.message);
        setOpenSnackbar(true);
      })
      .finally(() => setSubmitting(false));
  };

  function setAndValidatePosting(posting) {
    setPosting(posting);
    setIsPostingValid(
      posting && posting.account && posting.amount && posting.currency
    );
  }

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const result1 = await accountingService.getAccountNames();
      const result3 = await accountingService.getFrequentDescriptions();
      if (isMounted) {
        result1.sort((a, b) => a.localeCompare(b));
        setAccounts(result1);
        result3.sort((a, b) => a.description.localeCompare(b.description));
        console.log(result3);
        setFrequentDescriptions(result3);
        setFdIdx(
          result3.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.description]: curr
            }),
            {}
          )
        );
      }
    };

    fetchData();

    return () => (isMounted = false);
  }, [lastTs]);

  const PostingListItem = (posting, index) => {
    return (
      <ListItem key={index}>
        <span
          onClick={() => setAndValidatePosting({ ...posting, index: index })}
        >
          {posting.account} {posting.currency}{" "}
          {(posting.amount / 100).toFixed(2)}
        </span>
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="Delete"
            onClick={() => {
              const postings = txn.postings.slice();
              postings.splice(index, 1);
              setTxn({ ...txn, postings: postings });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="date"
            label="Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={txn.date}
            onChange={e => setTxn({ ...txn, date: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            fullWidth
            id="description"
            name="description"
            label="Description"
            inputProps={{
              list: "descriptions"
            }}
            value={txn.description}
            onChange={e => setTxn({ ...txn, description: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            fullWidth
            id="notes"
            name="notes"
            label="Notes"
            value={txn.notes}
            onChange={e => setTxn({ ...txn, notes: e.target.value })}
          />
        </Grid>
      </Grid>
      <Typography variant="h5" align="center">
        Postings
      </Typography>
      <List>{txn.postings.map((t, i) => PostingListItem(t, i))}</List>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            fullWidth
            id="account"
            name="account"
            label="Account"
            inputProps={{
              list: "accounts"
            }}
            value={posting.account}
            onChange={e =>
              setAndValidatePosting({ ...posting, account: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            required
            id="amount"
            name="amount"
            label="Amount"
            type="number"
            inputProps={{
              step: "0.01"
            }}
            value={posting.amount / 100}
            onChange={e =>
              setAndValidatePosting({
                ...posting,
                amount: Math.round(100 * e.target.value)
              })
            }
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <TextField
            required
            id="currency"
            name="currency"
            label="Currency"
            inputProps={{
              list: "currencies"
            }}
            value={posting.currency}
            onChange={e =>
              setAndValidatePosting({ ...posting, currency: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            id="postingDescription"
            name="postingDescription"
            label="Description"
            value={posting.description}
            onChange={e =>
              setAndValidatePosting({ ...posting, description: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            id="postingDate"
            label="Date"
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            value={posting.date}
            onChange={e =>
              setAndValidatePosting({ ...posting, date: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!isPostingValid}
            onClick={() => {
              const postings = txn.postings.slice();
              if (posting.index < 0) {
                const mapped = { ...posting };
                delete mapped.index;
                postings.push(mapped);
              } else {
                const modified = { ...postings[posting.index], ...posting };
                delete modified.index;
                postings.splice(posting.index, 1, modified);
              }
              setTxn({ ...txn, postings: postings });
              setAndValidatePosting(emptyPosting(postings));
            }}
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {transaction ? (
          <React.Fragment>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={isSubmitting}
              onClick={() => {
                if (!window.confirm("Sure?")) {
                  return;
                }
                setSubmitting(true);
                accountingService
                  .remove(transaction)
                  .then(d => {
                    onChange();
                    setCurrentTransaction(null);
                    setTxn(emptyTransaction());
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              Delete
            </Button>
            <Button
              type="button"
              variant="contained"
              color="default"
              className={classes.button}
              disabled={isSubmitting}
              onClick={() => {
                setCurrentTransaction(null);
                setTxn(emptyTransaction());
              }}
            >
              Cancel
            </Button>
          </React.Fragment>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="default"
            className={classes.button}
            disabled={isSubmitting}
          >
            Clear
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={saveTransaction}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
      <datalist id="currencies">
        {currencies.map(c => (
          <option key={c}>{c}</option>
        ))}
      </datalist>
      <datalist id="accounts">
        {accounts.map(acc => (
          <option key={acc}>{acc}</option>
        ))}
      </datalist>
      <datalist id="descriptions">
        {frequentDescriptions.map(fd => (
          <option key={fd.description}>{fd.description}</option>
        ))}
      </datalist>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Container>
  );
};

export default AdvancedEditForm;
