import crypto from "crypto";

const Helper = ENCRYPTION_KEY => {
  const IV_LENGTH = 16; // For AES, this is always 16

  function encrypt(text) {
    let iv = crypto.randomBytes(IV_LENGTH);
    let cipher = crypto.createCipheriv(
      "aes-256-cbc",
      Buffer.from(ENCRYPTION_KEY),
      iv
    );
    let encrypted = cipher.update(text);

    encrypted = Buffer.concat([encrypted, cipher.final()]);

    return Promise.resolve(
      iv.toString("hex") + ":" + encrypted.toString("hex")
    );
  }

  function decrypt(text) {
    let textParts = text.split(":");
    let iv = Buffer.from(textParts.shift(), "hex");
    let encryptedText = Buffer.from(textParts.join(":"), "hex");
    let decipher = crypto.createDecipheriv(
      "aes-256-cbc",
      Buffer.from(ENCRYPTION_KEY),
      iv
    );
    let decrypted = decipher.update(encryptedText);

    decrypted = Buffer.concat([decrypted, decipher.final()]);

    return Promise.resolve(decrypted.toString());
  }

  return {
    encrypt,
    decrypt
  };
};

export default Helper;
