function getDefaultCurrency() {
  return getCurrencies().then(currencies => currencies[0]);
}

function getSourceAccounts() {
  return Promise.resolve([
    "Assets:Cash",
    "Assets:Galicia:Caja de Ahorro",
    "Liabilities:Visa",
    "Liabilities:Amex",
    "Assets:Galicia:Caja de Ahorro (USD)"
  ]);
}

function getCreditCardAccounts() {
  return Promise.resolve(["Liabilities:Visa", "Liabilities:Amex"]);
}

function getCurrencies() {
  return Promise.resolve(["ARS", "USD", "EUR"]);
}

function getDefaultEnvelopes() {
  return Promise.resolve([
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Home",
      description: "Utilities, services and home maintenance.",
      accounts: ["Expenses:Home:Utilities", "Expenses:Home:Maintenance"],
      currency: "ARS",
      fillDay: 7,
      fillAmount: 2000000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Groceries",
      description: "Groceries",
      accounts: ["Expenses:Groceries"],
      currency: "ARS",
      fillDay: 7,
      fillAmount: 1500000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Health & Beauty",
      description: "Medical and health expenses, gym, cosmetics, etc.",
      accounts: ["Expenses:Health & Beauty"],
      currency: "ARS",
      fillDay: 7,
      fillAmount: 1900000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Baby",
      description: "Baby clothes, hygenic products, educational material",
      accounts: ["Expenses:Baby", "Expenses:Baby:Education"],
      currency: "ARS",
      fillDay: 7,
      fillAmount: 1000000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Frida",
      description: "Frida",
      accounts: ["Expenses:Frida"],
      currency: "ARS",
      fillDay: 7,
      fillAmount: 250000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Public Transport",
      description: "Public Transport",
      accounts: ["Expenses:Public Transport"],
      currency: "ARS",
      fillAmount: 400000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Auto",
      description: "Car-related expenses",
      accounts: [
        "Expenses:Auto",
        "Expenses:Auto:Gas",
        "Expenses:Auto:Parking",
        "Expenses:Auto:Insurance",
        "Expenses:Auto:Tolls"
      ],
      currency: "ARS",
      fillAmount: 600000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Mantenimiento de capital",
      description: "Mantenimiento de capital",
      accounts: ["Expenses:Departamentos"],
      currency: "ARS",
      fillAmount: 400000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Clothes",
      description: "Clothes",
      accounts: ["Expenses:Clothes", "Expenses:Clothes:Maintenance"],
      currency: "ARS",
      fillAmount: 400000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Socials",
      description: "Socials",
      accounts: ["Expenses:Socials"],
      currency: "ARS",
      fillAmount: 600000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Eat Out & Take away",
      description: "yum yum",
      accounts: ["Expenses:Eat Out & Take Away"],
      currency: "ARS",
      fillAmount: 1200000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Books & Entertainment",
      description: "Read a book",
      accounts: [
        "Expenses:Entertainment",
        "Expenses:Entertainment:Recreation",
        "Expenses:Entertainment:Books & Newspapers",
        "Expenses:Entertainment:Streaming"
      ],
      currency: "ARS",
      fillAmount: 300000
    },
    {
      type: "envelope",
      envelopeType: "monthly",
      name: "Extras",
      description: "Gifts, toys, deco",
      accounts: [
        "Expenses:Extras",
        "Expenses:Extras:Gifts",
        "Expenses:Extras:Toys",
        "Liabilities:Edith"
      ],
      currency: "ARS",
      fillAmount: 300000
    },
    {
      type: "envelope",
      envelopeType: "yearly",
      name: "Travel",
      description: "It's London, baby!",
      accounts: ["Expenses:Travel"],
      currency: "USD",
      fillAmount: 700000
    }
  ]);
}

async function getAllSettings() {
  const currencies = await getCurrencies();
  const sourceAccounts = await getSourceAccounts();
  const creditCardAccounts = await getCreditCardAccounts();
  const defaultEnvelopes = await getDefaultEnvelopes();

  return {
    currencies,
    sourceAccounts,
    creditCardAccounts,
    defaultEnvelopes
  };
}

export { getAllSettings };
