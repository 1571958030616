import PouchDB from "pouchdb";
import findPlugin from "pouchdb-find";
import DesignDoc from "./DesignDoc";
import uuid from "uuid/v4";
import { isBalanced } from "../lib/TransactionHelpers";

const defaults = {
  name: "aoab_data"
};

const TXN_KEYS = ["description", "date", "postings", "notes", "_id", "_rev"];

function validateTransaction(txn) {
  if (!isBalanced(txn.postings)) {
    throw new Error("Transaction is not balanced");
  }
  if (!txn.date || !txn.date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
    throw new Error("Invalid date");
  }
  txn.postings.forEach(p => {
    if (!Number.isInteger(p.amount)) {
      throw new Error("Amount should be an integer");
    }
    if (!p.currency || p.currency.trim() === "") {
      throw new Error("Invalid currency");
    }
    if (p.amount === 0) {
      throw new Error("Amount can't be zero");
    }
  });
}

const DataDatabase = function(options = {}) {
  const opts = { ...defaults, ...options };

  const PDB = options.PouchDB || PouchDB;
  PDB.plugin(findPlugin);
  PDB.plugin(require("transform-pouch"));

  let db, changes;

  async function init() {
    db = new PDB(opts.name);

    var promises = [
      db.put(DesignDoc).catch(e => {
        if (e.name === "conflict") {
          return;
        }
        throw e;
      }),
      db.createIndex({
        index: {
          fields: ["type", "accounts", "_id"]
        }
      })
    ];

    await Promise.all(promises);

    changes = db.changes({
      live: true,
      since: "now",
      include_docs: true,
      filter: doc => "transaction" === doc.type
    });
  }

  async function saveTransaction(doc) {
    validateTransaction(doc);
    let mine = {};
    TXN_KEYS.forEach(k => (mine[k] = doc[k]));
    if (mine._id && !mine._id.startsWith(mine.date)) {
      await remove(mine);
      mine = {};
      TXN_KEYS.forEach(k => (mine[k] = doc[k]));
      delete mine._id;
      delete mine._rev;
    }
    if (!mine._id) {
      mine._id = mine.date + "-" + uuid();
    }
    mine.type = "transaction";
    if (!mine.createdAt) {
      mine.createdAt = Date.now();
    }
    if (!mine.notes || mine.notes.match(/^\s+$/)) {
      delete mine.notes;
    }
    const accounts = {};
    doc.postings.forEach(p => (accounts[p.account] = true));
    mine.accounts = Object.keys(accounts);
    const savedInfo = await db.put(mine);
    return db.get(savedInfo.id);
  }

  function allTransactions() {
    return db
      .allDocs({ include_docs: true })
      .then(res =>
        res.rows.map(r => r.doc).filter(d => d.type === "transaction")
      );
  }

  function allPrices() {
    return db
      .allDocs({ include_docs: true })
      .then(res => res.rows.map(r => r.doc).filter(d => d.type === "price"));
  }

  function getTransactions(account, limit = 5, lastId = null) {
    const accountSelector = account ? { $all: [account] } : { $exists: true };
    const _idSelector = null === lastId ? { $gte: null } : { $lt: lastId };
    return db
      .find({
        selector: {
          type: "transaction",
          accounts: accountSelector,
          _id: _idSelector
        },
        sort: [{ _id: "desc" }],
        limit: limit
      })
      .then(result => result.docs);
  }

  function getTransactionsByDate(dateFrom, dateTo) {
    if (dateFrom > dateTo) {
      return Promise.reject(new Error("from should be less or equal to to"));
    }
    return db
      .find({
        selector: {
          type: "transaction",
          _id: { $gte: dateFrom, $lte: dateTo + "\ufff0" }
        },
        sort: [{ _id: "asc" }]
      })
      .then(result => result.docs);
  }

  async function remove(doc) {
    // this makes it work in filtered replication
    const toDelete = await db.get(doc._id, {rev: doc._rev});
    toDelete._deleted = true;
    return db.put(toDelete);
  }

  async function renameAccount(from, to) {
    async function f(from, to, count = 0) {
      const rows = await getTransactions(from, 100);
      if (rows.length === 0) {
        return Promise.resolve(count);
      }
      const promises = rows.map(doc => {
        doc.postings.forEach(p => {
          if (p.account === from) {
            p.account = to;
          }
        });
        return saveTransaction(doc);
      });
      promises.push(new Promise(resolve => setTimeout(resolve, 1000)));
      return Promise.all(promises).then(() => f(from, to, count + rows.length));
    }

    return f(from, to);
  }

  async function saveEnvelope(doc) {
    if (!doc._id) {
      doc._id = uuid();
    }
    await db.put({ ...doc, type: "envelope" });
    return db.get(doc._id);
  }

  async function savePrice(doc) {
    if (!doc._id) {
      doc._id = [doc.date, doc.commodityA, doc.commodityB, uuid()].join("-");
    }
    await db.put({ ...doc, type: "price" });
    return db.get(doc._id);
  }

  function getEnvelopes() {
    return db
      .find({
        selector: {
          type: "envelope"
        }
      })
      .then(result => {
        const envelopes = result.docs.slice();
        envelopes.sort((a, b) => a.order - b.order);
        return envelopes;
      });
  }

  function getAccountNames() {
    return db.query("accounts/names", { group: true }).then(data => {
      data.rows.sort((a, b) => b.value - a.value);
      return data.rows.map(r => r.key);
    });
  }

  function allDocs(opts) {
    return db.allDocs(opts);
  }

  async function restart(destroy = false) {
    await shutdown(destroy);
    await init();
  }

  async function shutdown(destroy = false) {
    changes.cancel();
    changes = null;
    if (destroy) {
      await db.destroy();
    } else {
      await db.close();
    }
  }

  return {
    init,
    saveTransaction,
    saveEnvelope,
    savePrice,
    getTransactions,
    getTransactionsByDate,
    getEnvelopes,
    getAccountNames,
    renameAccount,
    remove,
    allDocs,
    allTransactions,
    allPrices,
    restart,
    shutdown,
    db() {
      return db;
    },
    changes() {
      return changes;
    }
  };
};

export default DataDatabase;
