import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Dinero from "dinero.js";
import TransactionList from "./TransactionList";

const formatAmount = (amount, currency) => {
  return Dinero({ amount, currency }).toFormat("0,0");
};

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

const Import = props => {
  const classes = useStyles();

  const { accountingService } = props;

  const [loaded, setLoaded] = React.useState(false);
  const [txns, setTxns] = React.useState([]);
  const [duplicateTxns, setDuplicateTxns] = React.useState([]);
  const [duplicates, setDuplicates] = React.useState([]);

  const handleChooseFile = e => {
    const file = e.target.files[0];
    console.log(file);
    const reader = new FileReader();

    reader.onload = async e => {
      const dataRaw = e.target.result;
      const data = JSON.parse(dataRaw);
      const possibleDuplicates = await accountingService.findDuplicates(data);
      const noDuplicates = [];
      const duplicates = [];
      const duplicateTxns = [];
      for (let i = 0; i < data.length; i++) {
        if (possibleDuplicates[i].length === 0) {
          noDuplicates.push(data[i]);
        } else {
          duplicateTxns.push(data[i]);
          duplicates.push({
            txn: data[i],
            matches: possibleDuplicates[i]
          });
        }
      }

      setTxns(noDuplicates);
      setDuplicates(duplicates);
      setDuplicateTxns(duplicateTxns);
      console.log("Duplicates", duplicates);
      setLoaded(true);
    };
    reader.readAsText(file);
  };

  const cancel = () => {
    setTxns([]);
    setDuplicates([]);
    setDuplicateTxns([]);
    setLoaded(false);
  };

  const handleImport = async () => {
    const p = Promise.resolve();
    txns
      .reduce(
        (prev, curr) =>
          prev.then(() => {
            console.log("Saving", curr);
            return accountingService.saveTransaction(curr);
          }),
        p
      )
      .then(() => cancel());
  };

  const handleImportDuplicates = async () => {
    const p = Promise.resolve();
    duplicateTxns
      .reduce(
        (prev, curr) =>
          prev.then(() => {
            console.log("Saving", curr);
            return accountingService.saveTransaction(curr);
          }),
        p
      )
      .then(() => setDuplicateTxns([]));
  };

  const handleExportHLedger = async () => {
    const rows = await accountingService.convertAllTransactionsToHLedger();

    const data = rows.join("\n\n");

    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "journal.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleExportJSON = async () => {
    const rows = await accountingService.convertAllTransactionsToJSObjects();

    const data = JSON.stringify(rows);

    const element = document.createElement("a");
    const file = new Blob([data], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "data.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleExportBeancount = async () => {
    const rows = await accountingService.convertAllTransactionsToBeancount();

    const data = rows.join("\n\n");

    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "journal.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div>
      {loaded ? (
        <React.Fragment>
          <Typography>
            Loaded {txns.length} transactions, skipped {duplicates.length}{" "}
            possible duplictes
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleImport}
          >
            Import
          </Button>
          {duplicates.length && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleImportDuplicates}
            >
              Import duplicates
            </Button>
          )}

          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={cancel}
          >
            Cancel
          </Button>
          <TransactionList transactions={txns} />
          {duplicates.length && (
            <>
              <Divider />
              <TransactionList transactions={duplicateTxns} />
            </>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <input
            accept="application/json"
            className={classes.input}
            id="outlined-button-file"
            type="file"
            onChange={handleChooseFile}
          />
          <label htmlFor="outlined-button-file">
            <Button
              variant="outlined"
              component="span"
              className={classes.button}
            >
              Upload
            </Button>
          </label>
          <label>
            <Button
              onClick={handleExportHLedger}
              variant="outlined"
              component="span"
              className={classes.button}
            >
              Export hledger
            </Button>
          </label>
          <label>
            <Button
              onClick={handleExportJSON}
              variant="outlined"
              component="span"
              className={classes.button}
            >
              Export JSON
            </Button>
          </label>
        </React.Fragment>
      )}
    </div>
  );
};

export default Import;
