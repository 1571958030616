import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import { Formik, Field } from "formik";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const EditForm = props => {
  const classes = useStyles();

  const {
    accountingService,
    lastTs,
    onChange,
    transaction,
    setCurrentTransaction,
    onSave,
    customizations
  } = props;

  const [envelopeAccounts, setEnvelopeAccounts] = React.useState([]);
  const [sourceAccounts, setSourceAccounts] = React.useState([]);
  const [frequentDescriptions, setFrequentDescriptions] = React.useState([]);
  const [fdIdx, setFdIdx] = React.useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { currencies, creditCardAccounts } = customizations;

  const isCreditCard = account => creditCardAccounts.indexOf(account) > -1;
  const isEnvelopeAccount = account => envelopeAccounts.indexOf(account) > -1;
  const isSourceAccount = account => sourceAccounts.indexOf(account) > -1;

  const mapTransaction = txn => {
    let envelopePosting = 0;
    if (!isEnvelopeAccount(txn.postings[0].account)) {
      if (isEnvelopeAccount(txn.postings[1].account)) {
        envelopePosting = 1;
      } else if (isSourceAccount(txn.postings[1].account)) {
        envelopePosting = 0;
      } else {
        envelopePosting = txn.postings[0].amount > 0 ? 0 : 1;
      }
    }
    const srcPosting = envelopePosting === 0 ? 1 : 0;
    return {
      date: txn.date,
      currency: txn.postings[0].currency,
      description: txn.description,
      notes: txn.notes,
      accountTo: txn.postings[envelopePosting].account,
      accountFrom: txn.postings[srcPosting].account,
      amount: txn.postings[envelopePosting].amount / 100,
      installments: 1
    };
  };

  const mapValues = values => {
    const amount = Math.round(100 * values.amount);
    const sourceAccount = values.accountFrom.trim();
    const modified = {
      date: values.date,
      description: values.description.trim(),
      notes: values.notes,
      postings: [
        {
          account: values.accountTo.trim(),
          amount: amount,
          currency: values.currency
        },
        {
          account: sourceAccount,
          amount: -1 * amount,
          currency: values.currency
        }
      ]
    };
    if (isCreditCard(sourceAccount) && values.installments > 1) {
      const postings = accountingService.getInstallments(
        values.date,
        modified.postings[1],
        values.installments
      );
      modified.postings = [modified.postings[0], ...postings];
      console.log(modified);
    }
    if (transaction) {
      return { ...transaction, ...modified };
    }
    return modified;
  };

  function handleCloseSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  React.useEffect(() => {
    let isMounted = true;

    const fetchEnvelopeAccounts = async () => {
      const result1 = await accountingService.getEnvelopeAccounts();
      const result2 = await accountingService.getSourceAccounts();
      const result3 = await accountingService.getFrequentDescriptions();
      if (isMounted) {
        setEnvelopeAccounts(result1);
        setSourceAccounts(result2);
        setFrequentDescriptions(result3);
        setFdIdx(
          result3.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.description]: curr
            }),
            {}
          )
        );
      }
    };

    fetchEnvelopeAccounts();

    return () => (isMounted = false);
  }, [accountingService, lastTs]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={mapTransaction(
        transaction || accountingService.getEmptyTransaction()
      )}
      validate={values => {
        let errors = {};
        if (values.amount === 0) {
          errors.amount = "Cannot be zero";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        accountingService
          .saveTransaction(mapValues(values))
          .then(d => {
            setOpenSnackbar(true);
            if (!transaction) {
              resetForm();
            }
            setSubmitting(false);
            onChange();
            if (onSave) onSave();
          })
          .catch(e => {
            console.log(e);
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setValues
        /* and other goodies */
      }) => {
        function handleDelete() {
          if (!window.confirm("Sure?")) {
            return;
          }
          setSubmitting(true);
          accountingService
            .remove(transaction)
            .then(d => {
              onChange();
              setValues(
                mapTransaction(accountingService.getEmptyTransaction())
              );
              setCurrentTransaction(null);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }

        function handleDescriptionChange(e) {
          if (e.target.value in fdIdx) {
            setValues({
              ...values,
              description: e.target.value,
              accountTo: fdIdx[e.target.value].accounts[0],
              accountFrom: fdIdx[e.target.value].accounts[1]
            });
          }
          handleChange(e);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <Field
                    id="date"
                    label="Date"
                    type="date"
                    component={TextField}
                    value={values.date}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                    required
                    id="currency"
                    name="currency"
                    label="Currency"
                    fullWidth
                    inputProps={{
                      list: "currencies"
                    }}
                    component={TextField}
                    value={values.currency}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Field
                    required
                    id="description"
                    name="description"
                    label="Description"
                    component={TextField}
                    value={values.description}
                    onChange={handleDescriptionChange}
                    onBlur={e => {
                      handleBlur(e);
                      document.getElementById("amount").focus();
                    }}
                    inputProps={{
                      list: "descriptions"
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Field
                    required
                    error={errors.amount}
                    type="number"
                    id="amount"
                    name="amount"
                    label="Amount"
                    component={TextField}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      step: "0.01"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    required
                    fullWidth
                    id="accountTo"
                    name="accountTo"
                    label="Envelope Account"
                    component={TextField}
                    value={values.accountTo}
                    inputProps={{
                      list: "envelopeAccounts"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setValues({ ...values, accountTo: "" });
                              document.getElementById("accountTo").focus();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    required
                    id="accountFrom"
                    name="accountFrom"
                    label="Source Account"
                    fullWidth
                    inputProps={{
                      list: "sourceAccounts"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setValues({ ...values, accountFrom: "" });
                              document.getElementById("accountFrom").focus();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    component={TextField}
                    value={values.accountFrom}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    id="notes"
                    name="notes"
                    label="Notes"
                    fullWidth
                    component={TextField}
                    value={values.notes}
                    onChange={handleChange}
                  />
                </Grid>
                {isCreditCard(values.accountFrom) ? (
                  <Grid item xs={12} sm={12}>
                    <Field
                      id="installments"
                      name="installments"
                      label="Installments"
                      fullWidth
                      type="number"
                      component={TextField}
                      value={values.installments}
                      onChange={handleChange}
                      inputProps={{
                        min: "1",
                        step: "1"
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <div className={classes.buttons}>
                {transaction ? (
                  <React.Fragment>
                    <Button
                      type="button"
                      disabled={isSubmitting}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                    <Button
                      type="button"
                      disabled={isSubmitting}
                      variant="contained"
                      color="default"
                      className={classes.button}
                      onClick={() => setCurrentTransaction(null)}
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() =>
                      setValues(
                        mapTransaction(accountingService.getEmptyTransaction())
                      )
                    }
                  >
                    Clear
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              </div>
              <datalist id="currencies">
                {currencies.map(c => (
                  <option key={c}>{c}</option>
                ))}
              </datalist>
              <datalist id="envelopeAccounts">
                {envelopeAccounts.map(acc => (
                  <option key={acc}>{acc}</option>
                ))}
              </datalist>
              <datalist id="sourceAccounts">
                {sourceAccounts.map(acc => (
                  <option key={acc}>{acc}</option>
                ))}
              </datalist>
              <datalist id="descriptions">
                {frequentDescriptions.map(fd => (
                  <option key={fd.description}>{fd.description}</option>
                ))}
              </datalist>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                ContentProps={{
                  "aria-describedby": "message-id"
                }}
                message={<span id="message-id">Saved!</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleCloseSnackbar}
                  >
                    <CloseIcon />
                  </IconButton>
                ]}
              />
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditForm;
