import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dinero from "dinero.js";
import moment from "moment";
import { formatAmount } from "./lib/Utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  inline: {
    display: "inline"
  },
  fab: {
    position: "fixed",
    zIndex: 1,
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const Transactions = props => {
  const classes = useStyles();

  const { transactions, selectedAccount, onClickTransaction } = props;

  const splitPositiveNegative = postings => {
    const positive = [];
    const negative = [];
    for (let p of postings) {
      p.amount >= 0 ? positive.push(p) : negative.push(p);
    }
    positive.sort((a, b) => b.amount - a.amount);
    negative.sort((a, b) => a.amount - b.amount);
    return [positive, negative];
  };

  const sortPostings = postings => {
    const [positive, negative] = splitPositiveNegative(postings);
    return positive.concat(negative);
  };

  function chooseCurrency(postings) {
    if (selectedAccount) {
      const filtered = postings.filter(p => p.account === selectedAccount);
      if (filtered.length) {
        return filtered[0].currency;
      }
    }
    return postings[0].currency;
  }

  const chooseAmount = postings => {
    const currency = chooseCurrency(postings);
    if (selectedAccount) {
      const filtered = postings.filter(p => p.account === selectedAccount);
      if (filtered.length) {
        return filtered
          .filter(p => p.currency === currency)
          .map(p => p.amount)
          .reduce((prev, curr) => prev + curr, 0);
      }
    }
    return postings
      .filter(p => p.amount >= 0 && p.currency === currency)
      .map(p => p.amount)
      .reduce((prev, curr) => prev + curr, 0);
  };

  const groupByDate = txns => {
    txns.sort((a, b) => b.date.localeCompare(a.date));
    const byDate = [];
    let currentDate = null;
    for (let originalTxn of txns) {
      const postings = sortPostings(originalTxn.postings);
      const txn = {
        original: originalTxn,
        date: originalTxn.date,
        postings: postings,
        currency: chooseCurrency(postings),
        amount: chooseAmount(postings)
      };
      if (txn.date === currentDate) {
        byDate[byDate.length - 1].txns.push(txn);
      } else {
        byDate.push({
          date: moment(txn.date),
          txns: [txn]
        });
        currentDate = txn.date;
      }
    }
    Object.values(byDate).forEach(data =>
      data.txns.sort((a, b) => b.original.createdAt - a.original.createdAt)
    );
    return byDate;
  };

  const getAccountName = postings => {
    if (postings.length === 1) {
      return postings[0].account.split(":").pop();
    }
    const sameAccount = postings.every(p => p.account === postings[0].account);
    if (sameAccount) {
      return `${postings[0].account.split(":").pop()} x ${postings.length}`;
    }
    return "(multiple)";
  };

  const displayAccounts = txn => {
    if (txn.postings.length === 2) {
      const partsTo = txn.postings[0].account.split(":");
      const partsFrom = txn.postings[1].account.split(":");
      return partsTo.pop() + " | " + partsFrom.pop();
    }
    const [positive, negative] = splitPositiveNegative(txn.postings);
    console.log(positive, negative);
    const nameTo = getAccountName(positive);
    const nameFrom = getAccountName(negative);
    return `${nameTo} | ${nameFrom}`;
  };

  const txnsByDate = groupByDate(transactions);

  return (
    <List className={classes.root} subheader={<li />}>
      {txnsByDate.map(group => (
        <li key={`section-${group.date}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader
              style={{
                paddingRight: 0,
                paddingLeft: 0
              }}
            >
              <Grid
                container
                alignItems="center"
                style={{
                  backgroundColor: "#efefef",
                  paddingRight: 16,
                  paddingLeft: 16
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    component="span"
                    color="textSecondary"
                    varliant="subtitle2"
                    align="left"
                  >
                    {group.date.format("dddd")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    component="div"
                    style={{ flex: 1 }}
                    color="textSecondary"
                    align="right"
                  >
                    {group.date.format("D MMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>
            {group.txns.map((txn, idx) => (
              <ListItem
                key={txn.original._id || `section-${group.date}-${idx}`}
                onClick={() => onClickTransaction(txn.original)}
              >
                <ListItemText
                  primary={
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Typography component="span" align="left">
                          {txn.original.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          component="div"
                          style={{ flex: 1 }}
                          align="right"
                        >
                          {formatAmount(txn.amount, txn.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  secondary={displayAccounts(txn)}
                />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default Transactions;
