import PouchDB from "pouchdb";
import bcrypt from "bcryptjs";

const defaults = {
  name: "aoab_settings"
};

const SYNC_SETTINGS_ID = "settings";
const PIN_ID = "pin";

const defaultSettings = {
  sync: {
    _id: SYNC_SETTINGS_ID,
    url: null,
    username: null,
    password: null,
    passphrase: null,
    autoSync: false
  },
  pin: null
};

const Settings = (options = {}) => {
  const opts = { ...defaults, ...options };

  const PDB = options.PouchDB || PouchDB;

  let db, lastSettings;

  async function init() {
    db = new PDB(opts.name);

    await db.get(SYNC_SETTINGS_ID).catch(e => db.put(defaultSettings.sync));
  }

  async function getPinSettings() {
    try {
      return await db.get(PIN_ID);
    } catch (e) {
      return null;
    }
  }

  function remove_(doc) {
    Object.keys(doc)
      .filter(k => k.startsWith("_"))
      .forEach(k => delete doc[k]);
    return doc;
  }

  async function getSettings() {
    const syncSettings = await db.get(SYNC_SETTINGS_ID);
    const pin = await getPinSettings();
    lastSettings = {
      sync: remove_(syncSettings),
      pin: pin
        ? {
            active: true,
            length: pin.length
          }
        : {
            active: false
          }
    };
    return lastSettings;
  }

  async function saveSettings(data) {
    if ("sync" in data) {
      const syncSettings = await db.get(SYNC_SETTINGS_ID);
      Object.keys(defaultSettings.sync)
        .filter(k => k !== "_id")
        .filter(k => k in data.sync)
        .forEach(k => (syncSettings[k] = data.sync[k]));
      await db.put(syncSettings);
    }
    if ("pin" in data) {
      const pinSettings = await getPinSettings();
      if (data.pin.pin && !data.pin.pin.match(/^[0-9]{3,8}$/)) {
        throw new Error("Invalid PIN");
      }
      if (null === pinSettings) {
        if (data.pin.pin && data.pin.verifyPin) {
          if (data.pin.pin !== data.pin.verifyPin) {
            throw new Error("PINs don't match");
          }
          const salt = bcrypt.genSaltSync(10);
          const hash = bcrypt.hashSync(data.pin.pin, salt);
          await db.put({
            _id: PIN_ID,
            hash,
            length: data.pin.pin.length
          });
        }
      } else {
        if (data.pin.currentPin) {
          const matches = await this.validatePin(data.pin.currentPin);
          if (!matches) {
            throw new Error("Invalid PIN");
          }
          if (data.pin.pin) {
            const salt = bcrypt.genSaltSync(10);
            const hash = bcrypt.hashSync(data.pin.pin, salt);
            pinSettings.hash = hash;
            pinSettings.length = data.pin.pin.length;
            await db.put(pinSettings);
          } else {
            db.remove(pinSettings);
          }
        }
      }
    }
    return this.getSettings();
  }

  async function validatePin(pin) {
    const pinSettings = await getPinSettings();
    if (!pinSettings) {
      return false;
    }
    if (pin.length !== pinSettings.length) {
      return false;
    }
    return bcrypt.compareSync(pin, pinSettings.hash);
  }

  async function restart(destroy = false) {
    await shutdown(destroy);
    await init();
  }

  async function shutdown(destroy = false) {
    if (destroy) {
      await db.destroy();
    } else {
      await db.close();
    }
  }

  return {
    init,
    getLastSettings() {
      return lastSettings;
    },
    getSettings,
    saveSettings,
    validatePin,
    restart,
    shutdown,
    db() {
      return db;
    }
  };
};

export default Settings;
