/*global emit b:true*/

const Doc = {
  _id: "_design/accounts",
  views: {
    names: {
      map: function(doc) {
        if (doc.type !== "transaction") {
          return;
        }
        doc.postings.forEach(function(p) {
          emit(p.account, 1);
        });
      }.toString(),
      reduce: "_count"
    },
    exchangeRates: {
      map: function(doc) {
        if (doc.type !== "transaction") {
          return;
        }
        var totalByCurrency = {};
        doc.postings.forEach(function(p) {
          if (p.currency in totalByCurrency) {
            totalByCurrency[p.currency] += p.amount;
          } else {
            totalByCurrency[p.currency] = p.amount;
          }
        });
        var entries = Object.entries(totalByCurrency);
        if (entries.length !== 2) {
          return;
        }
        entries.sort(function(a, b) {
          return a[0].localeCompare(b[0]);
        });
        emit(
          [
            entries[0][0],
            entries[1][0],
            doc.date,
            entries[0][1] > 0 ? "buy" : "sell"
          ],
          Math.abs(entries[1][1] / entries[0][1])
        );
      }.toString(),
      reduce: "_stats"
    }
  }
};

export default Doc;
