import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dinero from "dinero.js";
import { formatAmount } from "./lib/Utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 50
  }
}));

const Accounts = props => {
  const classes = useStyles();

  const { accountingService, lastTs, showInSnackbar } = props;

  const [currencies, setCurrencies] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const transformAccounts = accounts => {
    const newCurrencies = Object.keys(accounts);
    const accountsIdx = {};
    const zeroCurrencies = [];
    Object.entries(accounts).forEach(([currency, accountsData]) => {
      const allZero = Object.values(accountsData).every(
        summary => summary.sum == 0
      );
      if (allZero) {
        zeroCurrencies.push(currency);
        return;
      }
      Object.entries(accountsData).forEach(([account, summary]) => {
        if (account in accountsIdx) {
          accountsIdx[account].byCurrency[currency] = {
            ...summary,
            formatted: formatAmount(summary.sum, currency)
          };
        } else {
          accountsIdx[account] = {
            account,
            byCurrency: {}
          };
          newCurrencies.forEach(
            c =>
              (accountsIdx[account].byCurrency[c] = {
                sum: 0,
                count: 0,
                formatted: null
              })
          );
          accountsIdx[account].byCurrency[currency] = {
            ...summary,
            formatted: formatAmount(summary.sum, currency)
          };
        }
      });
    });

    const netWorth = {
      account: "(Net Worth)",
      byCurrency: {}
    };
    newCurrencies.forEach(c => {
      netWorth.byCurrency[c] = {
        sum: 0,
        formatted: null
      };
      if (accountsIdx["Assets"] && accountsIdx["Assets"].byCurrency[c]) {
        netWorth.byCurrency[c].sum += accountsIdx["Assets"].byCurrency[c].sum;
      }
      if (
        accountsIdx["Liabilities"] &&
        accountsIdx["Liabilities"].byCurrency[c]
      ) {
        netWorth.byCurrency[c].sum +=
          accountsIdx["Liabilities"].byCurrency[c].sum;
      }
      netWorth.byCurrency[c].formatted = formatAmount(
        netWorth.byCurrency[c].sum,
        c
      );
    });

    const newRows = Object.values(accountsIdx);
    newRows.sort((a, b) => a.account.localeCompare(b.account));
    newRows.unshift(netWorth);
    setCurrencies(newCurrencies.filter(c => zeroCurrencies.indexOf(c) < 0));
    setRows(newRows);
  };

  React.useEffect(() => {
    let mounted = true;
    const getAccounts = async () => {
      const currentAccounts = await accountingService.getAccountsBalance();
      if (mounted) {
        transformAccounts(currentAccounts);
      }
    };
    getAccounts();
    return () => (mounted = false);
  }, [accountingService, lastTs]);

  return (
    <div className={classes.root}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            {currencies.map(c => (
              <TableCell key={c} align="right">
                {c}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.account}>
              <TableCell component="th" scope="row">
                {row.account}
              </TableCell>
              {currencies.map(c => (
                <TableCell key={`${row.account}-${c}`} align="right">
                  {row.byCurrency[c].formatted}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Accounts;
