import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "typeface-roboto";
import * as serviceWorker from "./serviceWorker";
import * as customization from "./Customization";
import { Accounting, AccountingApp } from "aoab-core";
import { BrowserRouter as Router } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: error, info: info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <code>
            <pre>{JSON.stringify(this.state, null, 2)}</pre>
          </code>
        </div>
      );
    }
    return this.props.children;
  }
}

const swConfig = (() => {
  let registration;
  let waitingRegistration;

  const onSuccess = currentRegistration => {
    console.log("Service worker installed!");
    registration = currentRegistration;
  };
  const onUpdate = newRegistration => {
    console.log("We got an updated service worker!");
    waitingRegistration = newRegistration;
  };

  return {
    onSuccess,
    onUpdate,
    getRegistration: () => registration,
    getWaitingRegistration: () => waitingRegistration
  };
})();

const init = async () => {
  const customizations = await customization.getAllSettings();
  const service = await Accounting(AccountingApp(), customizations);
  ReactDOM.render(
    <ErrorBoundary>
      <Router>
        <App
          customizations={customizations}
          accountingService={service}
          swConfig={swConfig}
        />
      </Router>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
