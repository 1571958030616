import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ViewList from "@material-ui/icons/ViewList";
import Edit from "@material-ui/icons/Edit";
import Mail from "@material-ui/icons/Mail";
import Typography from "@material-ui/core/Typography";
import TableChart from "@material-ui/icons/TableChart";
import BarChartIcon from "@material-ui/icons/BarChart";
import PieChartIcon from "@material-ui/icons/PieChart";
import UploadIcon from "@material-ui/icons/CloudUpload";
import SyncIcon from "@material-ui/icons/Sync";
import Settings from "@material-ui/icons/Settings";
import EditForm from "./EditForm";
import AdvancedEditForm from "./AdvancedEditForm";
import Envelopes from "./Envelopes";
import Transactions from "./Transactions";
import SettingsForm from "./SettingsForm";
import Reports from "./Reports";
import ReportsColumn from "./ReportsColumn";
import Accounts from "./Accounts";
import Import from "./Import";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import RelfreshIcon from "@material-ui/icons/Refresh";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import { Switch, Route, Link, useHistory } from "react-router-dom";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
function ListItemLink(props) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={() => onClick(to)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}
const mainListItems = props => {
  const { onClick } = props;
  return (
    <div>
      <ListItemLink
        icon={<Mail />}
        primary={"Envelopes"}
        to={"/envelopes"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<Edit />}
        primary={"Add"}
        to={"/edit"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<Edit />}
        primary={"Add+"}
        to={"/advanced-edit"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<ViewList />}
        primary={"Transactions"}
        to={"/transactions"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<TableChart />}
        primary={"Accounts"}
        to={"/accounts"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<PieChartIcon />}
        primary={"Pie Chart"}
        to={"/reports/pie-chart"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<BarChartIcon />}
        primary={"Bar Chart"}
        to={"/reports/bar-chart"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<UploadIcon />}
        primary={"Import/Export"}
        to={"/import-export"}
        onClick={onClick}
      />
      <ListItemLink
        icon={<Settings />}
        primary={"Settings"}
        to={"/settings"}
        onClick={onClick}
      />
      <ListItem button onClick={() => window.location.reload(true)}>
        <ListItemIcon>
          <RelfreshIcon />
        </ListItemIcon>
        <ListItemText primary="Refresh" />
      </ListItem>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    display: "inline",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
}));

function App({ accountingService, swConfig, customizations }) {
  //TODO remove me

  window.accService = accountingService;

  const [lastTs, setLastTs] = React.useState(0);

  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const components = {
    "/envelopes": {
      title: "Envelopes"
    },
    "/edit": {
      title: "Edit"
    },
    "/advanced-edit": {
      title: "Advanced Edit"
    },
    "/transactions": {
      title: "Transactions"
    },
    "/settings": {
      title: "Settings"
    },
    "/reports/pie-chart": {
      title: "Pie Chart"
    },
    "/reports/bar-chart": {
      title: "Bar Chart"
    },
    "/accounts": {
      title: "Accounts"
    },
    "/import-export": {
      title: "Import / Export"
    }
  };

  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentTxn, setCurrentTxn] = React.useState(null);
  const [mainContent, setMainContent] = React.useState("/transactions");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [selectedAccount, setSelecteddAccount] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [lastSettingsTs, setLastSettingsTs] = React.useState(0);

  React.useEffect(() => {
    console.log("Adding handler")
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      var string = msg.toLowerCase();
      var substring = "script error";
      if (string.indexOf(substring) > -1){
        alert('Script Error: See Browser Console for Detail');
      } else {
        var message = [
          'Message: ' + msg,
          'URL: ' + url,
          'Line: ' + lineNo,
          'Column: ' + columnNo,
          'Error object: ' + JSON.stringify(error)
        ].join(' - ');
    
        alert(message);
      }
    
      return false;
    };
    
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const accounts = await accountingService.getAccountNames();
      accountingService.getFrequentDescriptions();
      if (isMounted) {
        accounts.sort((a, b) => a.localeCompare(b));
        setSearchOptions(accounts);
      }
    };
    getData();
    return () => (isMounted = false);
  }, [accountingService, lastTs]);

  React.useEffect(() => {
    let isMounted = true;

    const fetchSettings = async () => {
      const currentSettings = await accountingService.getSettings();
      if (isMounted) {
        setSettings(currentSettings);
      }
    };

    fetchSettings();

    return () => (isMounted = false);
  }, [accountingService, lastSettingsTs]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleToggleMainContent = name => {
    setMainContent(name);
    setOpen(false);
  };

  const editTransaction = txn => {
    setCurrentTxn(txn);
    if (accountingService.isSimpleTransaction(txn)) {
      setMainContent("/edit");
      history.push("/edit");
    } else {
      setMainContent("/advanced-edit");
      history.push("/advanced-edit");
    }
  };

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  function handleCloseSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  function showInSnackbar(message) {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  }

  function sync() {
    const sync = accountingService.sync();
    sync.on("change", change => {
      if (change.change.docs.length === 1) {
        //  window.alert(`${change.direction} ID: ${change.change.docs[0]._id}`)
      }
    });
    sync.on("complete", info => {
      if (!componentIsMounted.current) {
        return;
      }
      console.log("Sync Info", info);
      let downloaded = 0;
      let uploaded = 0;
      if (info.pull && info.pull.docs_written > 0) {
        downloaded = info.pull.docs_written;
        setLastTs(Date.now());
      }
      if (info.push && info.push.docs_written > 0) {
        uploaded = info.push.docs_written;
      }
      const message = `Sync finished: ↑ ${uploaded} / ↓ ${downloaded}`;
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    });
    sync.on("error", error => {
      if (!componentIsMounted.current) {
        return;
      }
      console.log("Sync Error", error);
      setSnackbarMessage("Sync error :( " + JSON.stringify(error, null, 2));
      setOpenSnackbar(true);
    });
  }

  function autoSync() {
    if (settings && settings.sync && settings.sync.autoSync) {
      sync();
    }
  }

  return (
    <div>
      <CssBaseline />
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} noWrap>
              {components[mainContent].title}
            </Typography>

            {mainContent === "/transactions" ? (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Filter…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{
                    "aria-label": "search",
                    list: "search-options"
                  }}
                  onChange={e => setSelecteddAccount(e.target.value)}
                />
                <datalist id="search-options">
                  {searchOptions.map((o, i) => (
                    <option key={i}>{o}</option>
                  ))}
                </datalist>
              </div>
            ) : null}

            <IconButton color="inherit" onClick={() => sync()}>
              <SyncIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <List>{mainListItems({ onClick: handleToggleMainContent })}</List>
      </SwipeableDrawer>
      <main>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path="/envelopes">
            <Envelopes accountingService={accountingService} lastTs={lastTs} />
          </Route>
          <Route exact path="/edit">
            <EditForm
              customizations={customizations}
              transaction={currentTxn}
              setCurrentTransaction={setCurrentTxn}
              accountingService={accountingService}
              lastTs={lastTs}
              onChange={() => {
                setLastTs(Date.now());
              }}
              onSave={() => autoSync()}
            />
          </Route>
          <Route exact path="/advanced-edit">
            <AdvancedEditForm
              transaction={currentTxn}
              setCurrentTransaction={setCurrentTxn}
              accountingService={accountingService}
              lastTs={lastTs}
              onChange={() => {
                setLastTs(Date.now());
              }}
              onSave={() => autoSync()}
            />
          </Route>
          <Route exact path={["/", "/transactions"]}>
            <Transactions
              accountingService={accountingService}
              lastTs={lastTs}
              editTransaction={editTransaction}
              accountFilter={selectedAccount}
            />
          </Route>
          <Route exact path="/accounts">
            <Accounts
              accountingService={accountingService}
              lastTs={lastTs}
              showInSnackbar={showInSnackbar}
            />
          </Route>
          <Route exact path="/reports/pie-chart">
            <Reports accountingService={accountingService} lastTs={lastTs} />
          </Route>
          <Route exact path="/reports/bar-chart">
            <ReportsColumn
              accountingService={accountingService}
              lastTs={lastTs}
            />
          </Route>
          <Route exact path="/import-export">
            <Import accountingService={accountingService} />
          </Route>
          <Route exact path="/settings">
            <SettingsForm
              accountingService={accountingService}
              swConfig={swConfig}
              lastTs={lastTs}
              onChange={() => {
                setLastSettingsTs(Date.now());
              }}
            />
          </Route>
        </Switch>
      </main>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

export default App;
