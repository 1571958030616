import ExpandAccount from "./ExpandAccount";

const AccountBalances = (sep = ":") => {
  const expandAccount = ExpandAccount(sep);

  function calculateAccountBalances(docs) {
    const byAccount = {};
    for (let i = 0; i < docs.length; i++) {
      const txn = docs[i];
      for (let p of txn.postings) {
        const accounts = expandAccount(p.account);
        for (let account of accounts) {
          if (account in byAccount) {
            if (p.currency in byAccount[account]) {
              byAccount[account][p.currency].sum += p.amount;
              byAccount[account][p.currency].count += 1;
            } else {
              byAccount[account][p.currency] = {
                sum: p.amount,
                count: 1
              };
            }
          } else {
            byAccount[account] = {
              [p.currency]: {
                sum: p.amount,
                count: 1
              }
            };
          }
        }
      }
    }
    const entries = Object.entries(byAccount);
    entries.sort((a, b) => a[0].localeCompare(b[0]));
    const out = [];
    entries.forEach(([account, byCurrency]) => {
      const accountEntry = {
        account,
        currencies: {}
      };
      Object.entries(byCurrency).forEach(([currency, value]) => {
        accountEntry.currencies[currency] = value;
      });
      out.push(accountEntry);
    });
    return out;
  }

  function calculateMonthlyAccountBalances(docs, ymFrom = null, ymTo = null) {
    const byMonth = {};
    for (let txn of docs) {
      const month = txn.date.substring(0, 7);
      for (let p of txn.postings) {
        const myMonth = p.date ? p.date.substring(0, 7) : month;
        if (!(myMonth in byMonth)) {
          byMonth[myMonth] = {};
        }
        const byAccount = byMonth[myMonth];
        const accounts = expandAccount(p.account);
        for (let account of accounts) {
          if (account in byAccount) {
            if (p.currency in byAccount[account]) {
              byAccount[account][p.currency].sum += p.amount;
              byAccount[account][p.currency].count += 1;
            } else {
              byAccount[account][p.currency] = {
                sum: p.amount,
                count: 1
              };
            }
          } else {
            byAccount[account] = {
              [p.currency]: {
                sum: p.amount,
                count: 1
              }
            };
          }
        }
      }
    }
    const entries = Object.entries(byMonth);
    entries.sort((a, b) => a[0].localeCompare(b[0]));
    const out = [];
    entries
      .filter(([month]) => {
        if (ymFrom && month < ymFrom) {
          return false;
        }
        if (ymTo && month > ymTo) {
          return false;
        }
        return true;
      })
      .forEach(([month, byAccount]) => {
        const monthEntry = {
          month,
          accounts: []
        };
        const accountEntries = Object.entries(byAccount);
        accountEntries.sort((a, b) => a[0].localeCompare(b[0]));
        accountEntries.forEach(([account, byCurrency]) => {
          const accountEntry = {
            account,
            currencies: {}
          };
          Object.entries(byCurrency).forEach(([currency, value]) => {
            accountEntry.currencies[currency] = value;
          });
          monthEntry.accounts.push(accountEntry);
        });
        out.push(monthEntry);
      });

    return out;
  }

  return { calculateAccountBalances, calculateMonthlyAccountBalances };
};

export default AccountBalances;
