import React from "react";
import { Pie } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dinero from "dinero.js";
import { Typography, TextField } from "@material-ui/core";

import { formatAmount } from "./lib/Utils";

function filterAndTransform(
  rawAccounts,
  prefix,
  exclude,
  currency,
  maxSlices,
  maxDepth
) {
  let filtered = [];
  let excluded = [];
  const accountIdx = {};
  if (!(currency in rawAccounts)) {
    return { labels: [], datasets: [] };
  }
  Object.entries(rawAccounts[currency]).forEach(([account, summary]) => {
    if (
      (account === prefix || account.startsWith(`${prefix}:`)) &&
      summary.sum > 0
    ) {
      const parts = account.split(":");
      const data = {
        name: account,
        sum: summary.sum,
        count: summary.count,
        depth: parts.length,
        parent: parts.length > 1 ? parts.slice(0, -1).join(":") : null
      };
      if (exclude && account.match(exclude)) {
        excluded.push(data);
      } else {
        filtered.push(data);
      }
    }
  });
  filtered = filtered.filter(a => a.name !== prefix && a.depth <= maxDepth);
  filtered.forEach(a => (accountIdx[a.name] = a));
  excluded.forEach(a => (accountIdx[a.name] = a));

  const accounts = Object.keys(accountIdx);
  accounts.sort();
  for (let i = 0; i < accounts.length - 1; i++) {
    const account = accounts[i];
    let myChildren = accounts
      .slice(i + 1)
      .filter(a => a.startsWith(`${account}:`));
    if (myChildren.length === 0) {
      continue;
    }
    let toSubstractSum = 0;
    let toSubstractCount = 0;
    Object.entries(accountIdx).forEach(([key, value]) => {
      if (myChildren.indexOf(key) > -1) {
        toSubstractSum += value.sum;
        toSubstractCount += value.count;
      }
    });
    accountIdx[accounts[i]].sum -= toSubstractSum;
    accountIdx[accounts[i]].count -= toSubstractCount;
  }

  filtered = filtered.filter(a => a.count > 0);
  filtered.sort((a, b) => b.sum - a.sum);
  if (filtered.length > maxSlices) {
    const others = filtered.slice(maxSlices - 1);
    const other = {
      name: "(other)",
      sum: others.reduce((prev, curr) => (prev += curr.sum), 0)
    };
    filtered.splice(maxSlices - 1, filtered.length - maxSlices + 1, other);
  }
  console.log(filtered);
  const formatted = {
    labels: filtered.map(a => a.name.replace(new RegExp(`^${prefix}:`), "")),
    datasets: [
      {
        data: filtered.map(a => a.sum),
        backgroundColor: [
          "#003f5c",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#d45087",
          "#f95d6a",
          "#ff7c43",
          "#ffa600"
        ]
      }
    ]
  };
  return formatted;
}

function getMonths() {
  let now = new Date();
  return [now.toISOString().substr(0, 7)].concat(
    [1, 2, 3].map(i => {
      now.setMonth(now.getMonth() - 1);
      return now.toISOString().substr(0, 7);
    })
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Reports = props => {
  const classes = useStyles();

  const { accountingService } = props;

  const months = getMonths();
  const currencies = accountingService.getCurrencies();
  const [month, setMonth] = React.useState(months[0]);
  const [currency, setCurrency] = React.useState(currencies[0]);
  const [data, setData] = React.useState({ labels: [], datasets: [] });
  const [total, setTotal] = React.useState(0);
  const [maxSlices, setMaxSlices] = React.useState(8);
  const [maxDepth, setMaxDepth] = React.useState(2);
  const [exclude, setExclude] = React.useState(
    "^Expenses:(Taxes:(Empleo|Ganancias)|Auto$)"
  );

  React.useEffect(() => {
    let mounted = true;
    const getAccounts = async () => {
      const currentAccountsWithMonth = await accountingService.getAccountsReport(
        month,
        month,
        false
      );
      const currentAccounts = {};
      Object.entries(currentAccountsWithMonth).forEach(
        ([currency, dataByDate]) => {
          currentAccounts[currency] = dataByDate[month];
        }
      );
      if (mounted) {
        const filtered = filterAndTransform(
          currentAccounts,
          "Expenses",
          exclude,
          currency,
          maxSlices,
          maxDepth
        );
        if (filtered.datasets.length > 0) {
          const newTotal = filtered.datasets[0].data.reduce(
            (prev, next) => prev + next,
            0
          );
          setTotal(newTotal);
        } else {
          setTotal(0);
        }

        setData(filtered);
      }
    };
    getAccounts();
    return () => (mounted = false);
  }, [accountingService, month, currency, maxSlices, maxDepth, exclude]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="month">Month</InputLabel>
        <Select
          native
          value={month}
          onChange={e => setMonth(e.target.value)}
          inputProps={{
            name: "month",
            id: "month"
          }}
        >
          {months.map(m => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="currency">Currency</InputLabel>
        <Select
          native
          value={currency}
          onChange={e => setCurrency(e.target.value)}
          inputProps={{
            name: "currency",
            id: "currency"
          }}
        >
          {currencies.map(m => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </Select>
      </FormControl>
      <Typography variant="h4" align="center">
        {formatAmount(total, currency)}
      </Typography>
      <Pie
        data={data}
        height={400}
        options={{
          responsive: true,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.labels[tooltipItem.index] || "";

                if (label) {
                  label += ": ";
                }

                var amount =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                label += formatAmount(amount, currency);

                label += ` (${((100 * amount) / total).toFixed(0)}%)`;

                //label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
              }
            }
          }
        }}
      />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="maxSlices" shrink="true">
          Max. Slices
        </InputLabel>
        <TextField
          id="maxSlices"
          value={maxSlices}
          onChange={e => setMaxSlices(e.target.value)}
          type="number"
          step="1"
          min="1"
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="maxDepth" shrink="true">
          Acc. Depth
        </InputLabel>
        <TextField
          id="maxDepth"
          value={maxDepth}
          onChange={e => setMaxDepth(e.target.value)}
          type="number"
          step="1"
          min="2"
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="exclude" shrink="true">
          Exclude
        </InputLabel>
        <TextField
          id="exclude"
          value={exclude}
          onChange={e => setExclude(e.target.value)}
          className={classes.textField}
          margin="normal"
        />
      </FormControl>
    </div>
  );
};

export default Reports;
